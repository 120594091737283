import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Mobile } from "./screens/Mobile";
import { Desktop } from "./screens/Desktop";
import { MobileScreen } from "./screens/MobileScreen";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Mobile />,
  },
  {
    path: "/mobile-375",
    element: <Mobile />,
  },
  {
    path: "/desktop-1512",
    element: <Desktop />,
  },
  {
    path: "/mobile-377",
    element: <MobileScreen />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
