import React from "react";
import "./style.css";

export const Mobile = () => {
  return (
    <div className="mobile">
      <div className="div-2">
        <div className="overlap-3">
          <img className="button-3" alt="Button" src="/img/button-2.svg" />
          <img className="group-7" alt="Group" src="/img/group-1.svg" />
          <img className="a-more-refined-3" alt="A more refined" src="/img/a-more-refined-smoking-experience-1.png" />
        </div>
        <div className="frame-29">
          <div className="frame-30">
            <div className="frame-31">
              <div className="frame-32">
                <img
                  className="from-the-grounds-of"
                  alt="From the grounds of"
                  src="/img/from-the-grounds-of-the-middle-east-to-you-1.png"
                />
                <img
                  className="a-more-refined-4"
                  alt="A more refined"
                  src="/img/a-more-refined-smoking-experience-1-2.png"
                />
              </div>
              <img
                className="khloud-is-a-premium-2"
                alt="Khloud is a premium"
                src="/img/khloud-is-a-premium-dokha-tobacco-hand-blended-from-personally-1.png"
              />
            </div>
          </div>
          <img className="frame-33" alt="Frame" src="/img/frame-44-1.png" />
          <img className="frame-34" alt="Frame" src="/img/frame-21-1.png" />
          <div className="frame-35">
            <div className="img-wrapper">
              <img
                className="its-strength-2"
                alt="Its strength"
                src="/img/it-s-strength-freshness-and-pure-flavour-is-a-direct-result-of-1.png"
              />
            </div>
            <div className="frame-36">
              <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-36-1.png" />
              <img className="rectangle-7" alt="Rectangle" src="/img/rectangle-109-1.png" />
            </div>
            <div className="group-8">
              <div className="overlap-group-6">
                <div className="rectangle-8" />
                <div className="rectangle-9" />
              </div>
            </div>
          </div>
          <div className="frame-37">
            <div className="frame-38">
              <div className="frame-39">
                <img className="img-2" alt="Dokha HISTORY" src="/img/dokha-history.png" />
                <img className="img-2" alt="Centuries of rush" src="/img/centuries-of-rush.png" />
              </div>
              <img
                className="the-history-of-dokha"
                alt="The history of dokha"
                src="/img/the-history-of-dokha-tobacco-stretches-as-far-back-as-the-14th-c.png"
              />
            </div>
          </div>
          <div className="frame-40">
            <div className="frame-41">
              <div className="frame-42">
                <img className="img-3" alt="Why khloud" src="/img/why-khloud.png" />
                <img className="img-3" alt="Tender yet" src="/img/tender-yet-extravagant.png" />
              </div>
              <img
                className="img-3"
                alt="Grown in the"
                src="/img/grown-in-the-mountainous-regions-of-the-uae-dried-in-the-sun-k.png"
              />
            </div>
          </div>
          <div className="frame-43">
            <div className="frame-44">
              <p className="p">WE ARE HERE TO PROVIDE</p>
              <div className="the-experiences-2">THE EXPERIENCES.</div>
            </div>
            <div className="frame-45">
              <img className="frame-46" alt="Frame" src="/img/frame-86.svg" />
              <div className="frame-47">
                <div className="frame-48">
                  <div className="group-9">
                    <div className="overlap-group-7">
                      <img className="vector-4" alt="Vector" src="/img/vector-1-3.svg" />
                      <img
                        className="mini-dropper-bottle-4"
                        alt="Mini dropper bottle"
                        src="/img/mini-dropper-bottle-mockup-copy-2-1.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="frame-49">
                  <div className="frame-50">
                    <div className="text-wrapper-9">ARABIC CUT</div>
                    <div className="frame-51">
                      <div className="text-wrapper-10">Intensity:</div>
                      <div className="text-wrapper-11">Medium</div>
                    </div>
                    <div className="frame-51">
                      <div className="text-wrapper-10">About:</div>
                      <p className="text-wrapper-12">
                        Premium Arabic cut Dokha blend for a more elongated, authentic smoking experience.
                      </p>
                    </div>
                  </div>
                  <img className="button-4" alt="Button" src="/img/button-3.svg" />
                </div>
              </div>
              <img className="frame-52" alt="Frame" src="/img/frame-85.svg" />
            </div>
          </div>
          <div className="frame-53">
            <div className="frame-54">
              <div className="group-10" />
              <img
                className="a-more-refined-5"
                alt="A more refined"
                src="/img/a-more-refined-smoking-experience-2-2.png"
              />
              <img className="button-4" alt="Button" src="/img/button-4.svg" />
            </div>
          </div>
          <div className="frame-55">
            <div className="frame-56">
              <div className="frame-57">
                <div className="frame-58">
                  <div className="text-wrapper-13">Khloud</div>
                  <div className="frame-59">
                    <div className="text-wrapper-14">Home</div>
                    <div className="text-wrapper-15">Shop</div>
                    <div className="text-wrapper-15">Contact</div>
                  </div>
                </div>
                <div className="frame-58">
                  <div className="text-wrapper-13">Policies</div>
                  <div className="frame-59">
                    <div className="text-wrapper-14">Privacy Policy</div>
                    <div className="text-wrapper-15">Shipping Policy</div>
                    <div className="text-wrapper-15">Terms &amp; Conditions</div>
                  </div>
                </div>
              </div>
              <div className="frame-60">
                <div className="frame-61">
                  <div className="text-wrapper-13">Social</div>
                  <div className="frame-59">
                    <div className="text-wrapper-14">Instagram</div>
                    <div className="text-wrapper-15">Twitter</div>
                    <div className="text-wrapper-15">Facebook</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-62">
              <img className="vector-5" alt="Vector" src="/img/vector-15-1.svg" />
              <div className="text-wrapper-16">Copyright 2022 - Khloud®</div>
            </div>
          </div>
        </div>
        <img className="frame-63" alt="Frame" src="/img/frame-96.svg" />
      </div>
    </div>
  );
};
