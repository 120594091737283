import React from "react";
import "./style.css";

export const MobileScreen = () => {
  return (
    <div className="mobile-screen">
      <div className="mobile-2">
        <div className="overlap-4">
          <img className="button-5" alt="Button" src="/img/button-5.svg" />
          <img className="group-11" alt="Group" src="/img/group-2.svg" />
          <img className="a-more-refined-6" alt="A more refined" src="/img/a-more-refined-smoking-experience-3.png" />
        </div>
        <div className="frame-64">
          <div className="frame-65">
            <div className="frame-66">
              <div className="frame-67">
                <img
                  className="from-the-grounds-of-2"
                  alt="From the grounds of"
                  src="/img/from-the-grounds-of-the-middle-east-to-you-2.png"
                />
                <img
                  className="a-more-refined-7"
                  alt="A more refined"
                  src="/img/a-more-refined-smoking-experience-2.png"
                />
              </div>
              <img
                className="khloud-is-a-premium-3"
                alt="Khloud is a premium"
                src="/img/khloud-is-a-premium-dokha-tobacco-hand-blended-from-personally-2.png"
              />
            </div>
          </div>
          <img className="frame-68" alt="Frame" src="/img/frame-44-2.png" />
          <img className="frame-69" alt="Frame" src="/img/frame-21-2.png" />
          <div className="frame-70">
            <div className="frame-71">
              <img
                className="its-strength-3"
                alt="Its strength"
                src="/img/it-s-strength-freshness-and-pure-flavour-is-a-direct-result-of-2.png"
              />
            </div>
            <div className="frame-72">
              <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-36-2.png" />
              <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-109-2.png" />
            </div>
            <div className="group-12">
              <div className="overlap-group-8">
                <div className="rectangle-12" />
                <div className="rectangle-13" />
              </div>
            </div>
          </div>
          <div className="frame-73">
            <div className="frame-74">
              <div className="frame-75">
                <img className="img-4" alt="Dokha HISTORY" src="/img/dokha-history-1.png" />
                <img className="img-4" alt="Centuries of rush" src="/img/centuries-of-rush-1.png" />
              </div>
              <img
                className="the-history-of-dokha-2"
                alt="The history of dokha"
                src="/img/the-history-of-dokha-tobacco-stretches-as-far-back-as-the-14th-c-1.png"
              />
            </div>
          </div>
          <div className="frame-76">
            <div className="frame-77">
              <div className="frame-78">
                <img className="img-5" alt="Why khloud" src="/img/why-khloud-1.png" />
                <img className="img-5" alt="Tender yet" src="/img/tender-yet-extravagant-1.png" />
              </div>
              <img
                className="img-5"
                alt="Grown in the"
                src="/img/grown-in-the-mountainous-regions-of-the-uae-dried-in-the-sun-k-1.png"
              />
            </div>
          </div>
          <div className="frame-79">
            <div className="frame-80">
              <p className="we-are-here-to-2">WE ARE HERE TO PROVIDE</p>
              <div className="the-experiences-3">THE EXPERIENCES.</div>
            </div>
            <div className="frame-81">
              <img className="frame-82" alt="Frame" src="/img/frame-86-1.svg" />
              <div className="frame-83">
                <div className="frame-84">
                  <div className="group-13">
                    <div className="overlap-group-9">
                      <img className="vector-6" alt="Vector" src="/img/vector-1-4.svg" />
                      <img
                        className="mini-dropper-bottle-5"
                        alt="Mini dropper bottle"
                        src="/img/mini-dropper-bottle-mockup-copy-2-2.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="frame-85">
                  <div className="frame-86">
                    <div className="text-wrapper-17">ARABIC CUT</div>
                    <div className="frame-87">
                      <div className="text-wrapper-18">Intensity:</div>
                      <div className="text-wrapper-19">Medium</div>
                    </div>
                    <div className="frame-87">
                      <div className="text-wrapper-18">About:</div>
                      <p className="text-wrapper-20">
                        Premium Arabic cut Dokha blend for a more elongated, authentic smoking experience.
                      </p>
                    </div>
                  </div>
                  <img className="button-6" alt="Button" src="/img/button-6.svg" />
                </div>
              </div>
              <img className="frame-88" alt="Frame" src="/img/frame-85-1.svg" />
            </div>
          </div>
          <div className="frame-89">
            <div className="frame-90">
              <div className="group-14" />
              <img
                className="a-more-refined-8"
                alt="A more refined"
                src="/img/a-more-refined-smoking-experience-4.png"
              />
              <img className="button-6" alt="Button" src="/img/button-7.svg" />
            </div>
          </div>
          <div className="frame-91">
            <div className="frame-92">
              <div className="frame-93">
                <div className="frame-94">
                  <div className="text-wrapper-21">Khloud</div>
                  <div className="frame-95">
                    <div className="text-wrapper-22">Home</div>
                    <div className="text-wrapper-23">Shop</div>
                    <div className="text-wrapper-23">Contact</div>
                  </div>
                </div>
                <div className="frame-94">
                  <div className="text-wrapper-21">Policies</div>
                  <div className="frame-95">
                    <div className="text-wrapper-22">Privacy Policy</div>
                    <div className="text-wrapper-23">Shipping Policy</div>
                    <div className="text-wrapper-23">Terms &amp; Conditions</div>
                  </div>
                </div>
              </div>
              <div className="frame-96">
                <div className="frame-97">
                  <div className="text-wrapper-21">Social</div>
                  <div className="frame-95">
                    <div className="text-wrapper-22">Instagram</div>
                    <div className="text-wrapper-23">Twitter</div>
                    <div className="text-wrapper-23">Facebook</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-98">
              <img className="vector-7" alt="Vector" src="/img/vector-15-2.svg" />
              <div className="text-wrapper-24">Copyright 2022 - Khloud®</div>
            </div>
          </div>
        </div>
        <img className="frame-99" alt="Frame" src="/img/frame-96-1.svg" />
      </div>
    </div>
  );
};
